import React from "react";
import {Helmet} from "react-helmet";
import {graphql} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";

import ProductPageContainer from "../../components/common/ProductPageContainer";
import FigureGrid from "../../components/common/FigureGrid";
import FigureAlt from "../../components/common/FigureAlt";
import ProductSpecificationsCard from "../../components/products/ProductSpecificationsCard";
import ShadowEgg from "../../components/common/ShadowEgg";
import ChevronLink from "../../components/common/ChevronLink";

import * as classes from "./tps-covid-19.module.scss";

export default function TpsCovid19Page({data}) {

    return (
        <ProductPageContainer className={classes.container}>
            <Helmet>
                <title>ТПС1Ков – Вирусная транспортная среда</title>
            </Helmet>
            <h1>Вирусная транспортная среда ТПС1Ков</h1>
            <FigureGrid>
                <FigureAlt>
                    <StaticImage
                        src="img/tps-covid-19-1.jpg"
                        alt="Различные фасовки транспортной среды для вирусов ТПС1Ков"
                        layout="constrained"
                        width={564}
                        height={316} />
                </FigureAlt>
                <FigureAlt>
                    <StaticImage
                        src="img/tps-covid-19-2.jpg"
                        alt="Транспортная среда для вирусов в пробирке 5 мл"
                        layout="constrained"
                        width={564}
                        height={316} />
                </FigureAlt>
            </FigureGrid>
            <div className={classes.contentContainer1}>
                <ProductSpecificationsCard className={classes.specificationsCard}>
                    <ProductSpecificationsCard.Entry
                        label="Регистрационное удостоверение"
                        value={
                            <ChevronLink
                                use="a"
                                href={data["registrationCertificateFile"]["publicURL"]}
                                target="_blank">РЗН 2021/14480 от 01.06.2021</ChevronLink>} />
                    <ProductSpecificationsCard.Entry
                        value={
                            <ChevronLink
                                use="a"
                                href={data["instructionsFile"]["publicURL"]}
                                target="_blank">Инструкция</ChevronLink>} />
                    <ProductSpecificationsCard.Entry
                        label="Срок годности"
                        value="12 месяцев" />
                    <ProductSpecificationsCard.Entry
                        label="Упаковка"
                        value="100 штук" />
                    <ProductSpecificationsCard.Entry
                        label="Объем"
                        value="1,5 мл, 5,0 мл" />
                    <ProductSpecificationsCard.Entry
                        label="Условия хранения"
                        value="Температура +4/+8 °С, отсутствие прямого солнечного света" />
                    <ProductSpecificationsCard.Entry
                        label="Условия использования"
                        value="Транспортировка с биоматериалом при комнатной температуре в течение 5 суток " />
                </ProductSpecificationsCard>
                    <h2>О продукте</h2>
                    <p>Вирусная транспортная среда ТПС1Ков поставляется в пробирках объемом 1,5 мл и 5 мл. Представляет собой прозрачную или слегка желтоватую жидкость без осадка, в состав которой входит азид натрия — консервант, в концентрации, безопасной для конечного пользователя. Не содержит спиртсодержащей продукции.</p>
            </div>
            <div className={classes.contentContainer2}>
                <div className={classes.contentWithEgg}>
                    <ShadowEgg className={classes.eggContainer}>
                        <StaticImage
                            src="img/tps-covid-19-3.jpg"
                            alt="Транспортная среда для коронавируса в эппендорфе"
                            layout="constrained"
                            width={564}
                            height={436} />
                    </ShadowEgg>
                    <h2>Назначение</h2>
                    <p>Предназначена для транспортирования и хранения образцов биологического материала человека (соскобы/мазки эпителиальных клеток из ротоглотки, носоглотки) с целью последующего анализа нуклеиновых кислот методом полимеразной цепной реакции.</p>
                    <p>Медицинское изделие является вспомогательным средством в клинической лабораторной диагностике и может быть использовано в клинико‑диагностических лабораториях медицинских учреждений и научно‑исследовательской практике.</p>
                    <p>Не предназначено для самотестирования. Потенциальным пользователем изделия является квалифицированный персонал, осуществляющий взятие и предобработку клинического материала, а также специалисты, обученные методам молекулярной диагностики и правилам работы в клинико‑диагностической лаборатории в установленном порядке.</p>
                    <p>Применение медицинского изделия не зависит от популяционных и демографических аспектов. Противопоказаний к применению нет.</p>
                </div>
                <h2>Технические особенности</h2>
                <p>Транспортировка и хранение ТПС1Ков в упаковке предприятия‑изготовителя осуществляется при температуре от 4°C до 8°C. Изделие поставляется в нестерильном виде и предназначено только для однократного применения по назначению.</p>
                <p>ТПС1Ков соответствует требованиям ТУ 21.20.23–003–48327621–2020 и заявляемым характеристикам в течение 12 месяцев при соблюдении условий хранения.</p>
                <p>После внесения биологического материала срок транспортировки и хранения при комнатной температуре до 5 суток.</p>
            </div>
        </ProductPageContainer>
    );

}

export const pageQuery = graphql`
    query {
        registrationCertificateFile: file(relativePath: {eq: "ru-rzn-2021-14480-ot-01-06-2021.pdf"}) {
            publicURL
        }
        instructionsFile: file(relativePath: {eq: "instruktsiia-virusnaia-transportnaia-sreda-tps1kov.pdf"}) {
            publicURL
        }
    }  
`;
