import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import * as classes from "./ProductPageContainer.module.scss";

export default function ProductPageContainer({className, ...props}) {
    return (
        <section className={classNames(classes.container, className)} {...props} />
    );
}

ProductPageContainer.propTypes = {
    className: PropTypes.string
};
