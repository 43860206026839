import React from "react";
import classNames from "classnames";

import Card from "../common/Card";

import * as classes from "./ProductSpecificationsCard.module.scss";

export default function ProductSpecificationsCard({className, children}) {
    return (
        <Card
            className={classNames(classes.container, className)}
            use="aside">
            <h2>Основные данные</h2>
            <dl>{children}</dl>
        </Card>
    );
}

function Entry({label, value}) {
    return (
        <div className={classes.entry}>
            <dt>{label}</dt>
            <dd>{value}</dd>
        </div>
    );
}

ProductSpecificationsCard.Entry = Entry;
