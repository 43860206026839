import React from "react";
import PropTypes from "prop-types";
import {Link} from "gatsby";
import classNames from "classnames";

import * as classes from "./ChevronLink.module.scss";
import ChevronLeft from "./img/chevron-left.svg";
import ChevronRight from "./img/chevron-right.svg";

export default function ChevronLink({
    className, chevronSide = "RIGHT", use: ContainerComponent = Link, children, ...props
}) {

    let ChevronComponent, chevronClassName;
    switch (chevronSide) {
        case "LEFT":
            ChevronComponent = ChevronLeft;
            chevronClassName = classes.chevronLeft;
            break;
        case "RIGHT":
            ChevronComponent = ChevronRight;
            chevronClassName = classes.chevronRight;
            break;
        default: throw new Error("Unreachable code");
    }

    return (
        <ContainerComponent
            className={classNames(classes.container, className)}
            {...props}>
            <ChevronComponent className={classNames(classes.chevron, chevronClassName)} />
            {children}
        </ContainerComponent>
    );

}

ChevronLink.propTypes = {
    className: PropTypes.string,
    chevronSide: PropTypes.oneOf(["LEFT", "RIGHT"]),
    use: PropTypes.elementType
};
