import React from "react";

import * as classes from "./FigureGrid.module.scss";

export default function FigureGrid({children}) {
    return (
        <div className={classes.outerContainer}>
            <div className={classes.innerContainer}>{children}</div>
        </div>
    );
}
